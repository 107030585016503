<template>
    <div>
        <!-- CAROUSEL-->
        <b-row align-h="center" align-v="center">
          <b-col cols="11" md="10" lg="10" xl="7" >
            <b-carousel
              class="c-shadow-sm mb-5"
              controls
              indicators
              img-width="1024"
              img-height="480"
            >

            
              <b-carousel-slide  img-src="https://teotihuacan.gob.mx/files/avisos/BANNER-WEB-PRIMER-INFORME.png">
              </b-carousel-slide>
            </b-carousel>
          </b-col>
        </b-row >
        <br><br><br>

         <b-row align-h="center" align="center" class="mb-5">
      <b-col cols="9" md="5" lg="3" class="mb-5">
        <a href="https://drive.google.com/file/d/1HVQY8pworFcPpwFP-_sRw7VG_ReNkZE6/view?usp=share_link" target="blank">
        <b-img  fluid alt="Responsive image" class="c-shadow-sm" src="https://teotihuacan.gob.mx/files/informe/Portada Informe.png">
        </b-img>
        </a>
      </b-col>

      <b-col cols="12" class="mb-5">
        <a href="https://drive.google.com/file/d/1HVQY8pworFcPpwFP-_sRw7VG_ReNkZE6/view?usp=share_link" target="blank">
          <b-button variant="secondary" pill>Descargar <b-icon icon="arrow-down-circle-fill"/></b-button>
        </a>
      </b-col>
      
    </b-row>

      
      <b-row align-h="center" class="mt-5 mb-5">
        <b-col cols="12" lg="8" xl="6">
          <div>
            <video autoplay controls muted ref="videoRef" src="" id="video-container" width="100%"  ></video>
          </div>
        </b-col>
      </b-row>
    </div>
</template>
<script>
  export default {
    components: {
    },
    mounted: function() {
      this.$refs.videoRef.src = "https://teotihuacan.gob.mx/files/informe/informe1.mp4";
      this.$refs.videoRef.play();
    },
    data() {
      return {
        resources:null,
      }
    }
    
  }
</script>